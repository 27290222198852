export const getNiceFormatDateString = (date: Date, locale = "en") => {
  if (!date) return '';

  if (typeof date === 'string') {
    date = new Date(date);
  }

  return new Intl.DateTimeFormat(locale, {
    month: "long",
    day: "numeric",
    year: "numeric"
  }).format(date);
}

export const getNiceFormatDateTimeString = (date: Date, locale = "en") => {
  if (!date) return '';

  if (typeof date === 'string') {
    date = new Date(date);
  }

  return Intl.DateTimeFormat(locale, {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  }).format(date);
}

// time: milliseconds
export const waitDelay = (time: number) => {
  return new Promise<void>((resolve, reject) => {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      resolve();
    }, time)
  })
}