import AirtableTable from "../AirtableTable";
import { Calendar } from "../models";

export class CalendarTable extends AirtableTable<Calendar> {
  constructor(instance: any) {
    super(instance, "Calendar");

    this.fields = [
      "Name",
      "Image",
      "Date",
      "End Date/Time",
      "Description",
      "URL"
    ]
  }

  getUpcomings = () => {
    return this.list({
      filterByFormula: "{Date} > NOW()"
    })
  }
}
