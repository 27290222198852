import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/AuthProvider";
import { Button } from "../../components/buttons/button";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

export default function ErrorPage() {
  const auth = useAuth();
  let navigate = useNavigate();

  if (!auth.triedEmail) return null;

  return (
    <main className="flex items-center justify-center md:h-screen">
      <div className="relative mx-auto flex w-full max-w-[800px] flex-col space-y-2.5 p-4 md:-mt-32">
        <div className="flex-1 rounded-lg bg-gray-50 px-6 pb-8 pt-8">
          <section className="text-center">
            <h3 className="mb-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-4xl">Registration Required</h3>
            <p className="mb-6 text-lg font-normal text-gray-500 dark:text-gray-400">We notice your e-mail, {auth.triedEmail}, doesn&apos;t have a Learning Hub account yet.</p>
            <p className="mb-6 text-lg font-normal text-gray-500 dark:text-gray-400">Click Register below to complete a few short steps and then you'll be brought into the platform for access to the resources.</p>
            <div className="flex max-w-[480px] m-auto">
              <div className="w-1/2 p-2">
                <Button
                  className="h-12 rounded-lg"
                  buttonType="outline"
                  onClick={() => navigate("/login")}
                >
                  <ChevronLeftIcon className="ml-auto h-5 w-5 text-blue-700 hover:text-white" />&nbsp;&nbsp;Return to login
                </Button>
              </div>
              <div className="w-1/2 p-2 text-right">
                <Button
                  className="h-12"
                  buttonType="filled"
                  onClick={() => navigate("/register")}
                >
                  Register&nbsp;&nbsp;<ChevronRightIcon className="ml-auto h-5 w-5 text-white " />
                </Button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
}
