import { useNavigate } from "react-router-dom";
import { Button } from "../../components/buttons/button";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

export default function ErrorPage() {
  let navigate = useNavigate();

  return (
    <main className="flex items-center justify-center md:h-screen">
      <div className="relative mx-auto flex w-full max-w-[1040px] flex-col space-y-2.5 p-4 md:-mt-32">
        <div className="flex-1 rounded-lg bg-gray-50 px-6 pb-8 pt-8">
          <section className="text-center">
            <h3 className="mb-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-4xl">Legacy Schools Registration Required</h3>
            <p className="mb-6 text-lg font-normal text-gray-500 dark:text-gray-400">Before accessing the Learning hub, you must first <a href="https://downiewenjack.ca/our-work/legacy-schools-programs/#legacyschoolsregistration">register to the Legacy Schools program</a>. Once you submit your registration form, you should hear back within 3 business days and then you'll have access to login and explore the Learning Hub resources!</p>
            <div className="flex max-w-[480px] m-auto">
              <div className="w-1/2 p-2">
                <Button
                  className="h-12 rounded-lg"
                  buttonType="outline"
                  onClick={() => navigate("/login")}
                >
                  <ChevronLeftIcon className="ml-auto h-5 w-5 text-blue-700 hover:text-white" />&nbsp;&nbsp;Return to login
                </Button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
}
