import { createContext, ReactNode, useContext } from 'react';
import { useAuthProvider } from './useAuthProvider';
import { Navigate, useLocation } from 'react-router-dom';
import { PageLoading } from '../components';

interface AuthContextType {
  user: any;
  triedEmail: string;
  loginUser: Function;
  registerUser: Function;
  logout: Function;
}

let AuthContext = createContext<AuthContextType>(null!);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const {
    loading,
    user,
    triedEmail,
    loginUser,
    registerUser,
    logout
  } = useAuthProvider();

  let value = {
    user,
    triedEmail,
    loginUser,
    registerUser,
    logout
  };

  if (loading) {
    return (<PageLoading />)
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  return useContext(AuthContext);
}

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default AuthProvider;