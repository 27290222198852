import { HeaderWrapper, LogoContainer, NavItem } from "./styles";
import { NavLink } from "react-router-dom";
import logoImg from "../../assets/images/logo.png";

export default function Header() {
  return (
    <HeaderWrapper>
      <div className="max-w-7xl mx-auto flex items-center h-full justify-between">
        <NavLink to="https://downiewenjack.ca/">
          <LogoContainer>
            <img src={logoImg} alt="Header Logo" />
          </LogoContainer>
        </NavLink>

        <div className="flex items-center gap-4">
          <NavLink to="https://downiewenjack.ca/">
            <NavItem>Home</NavItem>
          </NavLink>

          <div className="h-4 w-px bg-gray-600"></div>

          <NavLink to="https://downiewenjack.ca/mental-health-resources/">
            <NavItem>Mental Health Resources</NavItem>
          </NavLink>

          <div className="h-4 w-px bg-gray-600"></div>

          <NavLink to="https://downiewenjack.ca/news-publications/subscribe/">
            <NavItem>Subscribe</NavItem>
          </NavLink>
        </div>
      </div>
    </HeaderWrapper>
  )
}
