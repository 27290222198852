import { z } from "zod";
import {
  AtSymbolIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ExclamationCircleIcon,
  UserCircleIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { Button } from "../buttons/button";
import { FieldError, FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Fragment, useState } from "react";
import FormItem, { SelectFormItem } from "../FormItem";
import styles from "../styles/slider.module.scss";
import { registerFormSchema } from "../../lib/formSchemas";
import { useAuth } from "../../auth/AuthProvider";
import { AvailableGrades, IndigenousGroup } from "../../helpers/constants";
import { useSelector } from "react-redux";
import { Subject } from "../../lib/airtables/models";
import { useNavigate } from "react-router-dom";

export default function RegisterForm() {
  const auth = useAuth();
  const form = useForm<z.infer<typeof registerFormSchema>>({
    resolver: zodResolver(registerFormSchema),
    defaultValues: {
      email: auth.triedEmail,
      name: "",
      pronouns: "",
      identify: [],
      grades: [],
      subjects: []
    },
  });
  const { totalSubjects } = useSelector((state: any) => state.app);
  const { register, formState: { errors }, handleSubmit, control } = form;
  const [isPending, setIsPending] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  let navigate = useNavigate();

  async function onSubmit(values: z.infer<typeof registerFormSchema>) {
    setIsPending(true);
    const res = await auth.registerUser(values);
    if (res && res.user) {
      navigate("/welcome");
    } else if (res && res.error) {
      navigate("/user-error");
    }
    setIsPending(false);
  }

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? 0 : prevIndex - 1))
  }

  const handleNext = () => {
    if (currentIndex === 0) {
      setCurrentIndex((prevIndex) => prevIndex === 3 ? 3 : prevIndex + 1)
      return;
    }

    handleSubmit(onSubmit)();
  }

  const errorMessage = Object.keys(errors).length ? "There are some fields to need to fix." : ""

  return (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-3"
      >
        <div className="flex-1 rounded-lg bg-gray-50 px-6 pb-4 pt-8">
          <div className="text-center px-8">
            <h2 className="mb-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-4xl dark:text-white">Create an account</h2>
            <p className="mb-6 text-md font-normal text-gray-500 dark:text-gray-400">Please complete a few short steps that will help us personalize the platform for your teaching focus.</p>
          </div>
          <div className={styles.sliderContainer}>
            <div
              className={styles.sliderSteps}
              style={{
                transform: `translateX(-${currentIndex * 100}%)`,
              }}
            >
              <div className={styles.sliderStep}>
                <div className="w-full mt-4 px-8">
                  <FormItem
                    register={register}
                    fieldName="E-mail"
                    fieldId="email"
                    required={true}
                    disabled={isPending}
                    containerClass="text-left"
                    placeholder="username@email.com"
                    icon={(<AtSymbolIcon className="pointer-events-none absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />)}
                    error={errors?.email}
                  />
                  <FormItem
                    register={register}
                    fieldName="Full Name"
                    fieldId="name"
                    required={true}
                    disabled={isPending}
                    placeholder="Amanda Stanger"
                    error={errors?.name}
                    containerClass="mt-4 text-left"
                    icon={(<UserIcon className="pointer-events-none absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />)}
                  />
                  <FormItem
                    register={register}
                    fieldName="Pronouns"
                    fieldId="pronouns"
                    required={false}
                    disabled={isPending}
                    placeholder="he/him, she/her, they/them"
                    error={errors?.pronouns}
                    containerClass="mt-4 text-left"
                    icon={(<UserCircleIcon className="pointer-events-none absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />)}
                  />
                </div>
              </div>
              <div className={styles.sliderStep}>
                <div className="w-full mt-4">
                  <SelectFormItem
                    control={control}
                    fieldName="Do you identify as First Nations, Inuit and/or Métis?"
                    fieldId="identify"
                    required={false}
                    placeholder=""
                    disabled={isPending}
                    error={errors?.identify as FieldError}
                    containerClass="text-left"
                    options={IndigenousGroup.map(item => ({ label: item, value: item }))}
                  />

                  <SelectFormItem
                    control={control}
                    fieldName="Select the grades you teach:"
                    fieldId="grades"
                    required={false}
                    placeholder=""
                    disabled={isPending}
                    error={errors?.grades as FieldError}
                    containerClass="mt-4 text-left"
                    options={AvailableGrades.map(item => ({ label: item, value: item }))}
                  />

                  <SelectFormItem
                    control={control}
                    fieldName="Select the subjects you teach:"
                    fieldId="subjects"
                    required={false}
                    placeholder=""
                    disabled={isPending}
                    error={errors?.subjects as FieldError}
                    containerClass="mt-4 text-left"
                    options={totalSubjects.map((item: Subject) => ({ label: item["Subject"] ?? "", value: item.id ?? "" }))}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex h-8 items-end space-x-1"
            aria-live="polite"
            aria-atomic="true"
          >
            {errorMessage && (
              <>
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                <p className="text-sm text-red-500">{errorMessage}</p>
              </>
            )}
          </div>
          <div className="flex max-w-[320px] m-auto mt-8">
            <div className="flex justify-start w-1/2 p-2">
              {currentIndex !== 0 && (
                <Button
                  className="rounded-lg"
                  buttonType="outline"
                  type="button"
                  aria-disabled={isPending}
                  onClick={() => handlePrev()}
                >
                  <ChevronLeftIcon className="ml-auto h-5 w-5 text-blue-700 hover:text-white" />&nbsp;&nbsp;Back
                </Button>
              )}
            </div>
            <div className="flex justify-end w-1/2 p-2 text-right">
              <Button
                className="rounded-lg"
                buttonType="filled"
                type="button"
                onClick={() => handleNext()}
                aria-disabled={isPending}
              >
                {currentIndex === 1 ? "Register" : (
                  <Fragment>Next&nbsp;&nbsp;<ChevronRightIcon className="ml-auto h-5 w-5 text-white " /></Fragment>
                )}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
