import { FC } from "react";
import { Calendar } from "../../../lib/airtables/models";

interface CalendarProps {
  data: Calendar[];
}

const Calendars: FC<CalendarProps> = ({ data: calendars }) => {

  const renderEventDate = (event: Calendar) => {
    const date = new Date(event["Date"]);
    const monthFormatter = new Intl.DateTimeFormat('en-US', { month: 'short' });
    return (
      <div className="event-date text-center">
        <span className="text-md font-bold">{monthFormatter.format(date)}</span>
        <hr className="border-y-2 border-black my-1" />
        <span className="text-sm font-bold">{date.getDate()}</span>
      </div>
    )
  }

  const renderEventTitle = (event: Calendar) => {
    const date = new Date(event["Date"]);
    const formatter = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return (
      <div className="event-title flex flex-col gap-2 px-4">
        <span className="text-sm">{formatter.format(date)}</span>
        <span className="text-sm">{event["Name"]}</span>
      </div>
    )
  }

  return (
    <section className="upcoming-calendars">
      <div className="section-title my-2">
        <span className="text-base font-bold">Calendar</span>
      </div>
      <div className="calendars">
        {!!calendars && calendars.map((calendar, i) => (
          <div
            key={i}
            className="flex items-center py-2"
          >
            {renderEventDate(calendar)}
            {renderEventTitle(calendar)}
          </div>
        ))}
      </div>
    </section>
  )
}

export default Calendars;