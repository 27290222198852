import { Controller, FieldError } from "react-hook-form";
import Select from "react-select";

interface FormItemProps {
  register: Function;
  fieldName: string;
  fieldId: string;
  required?: boolean;
  placeholder?: string;
  containerClass?: string;
  icon?: any;
  error?: FieldError;
  disabled?: boolean;
}

export default function FormItem({
  register,
  fieldName,
  fieldId,
  required = false,
  placeholder,
  containerClass,
  disabled = false,
  icon,
  error
}: FormItemProps) {
  return (
    <div className={containerClass}>
      <label
        className="mb-3 mt-5 block text-xs font-medium text-gray-900"
        htmlFor={fieldId}
      >
        {fieldName}
        {required && (<span className="field-required">*</span>)}
      </label>
      <div className="relative">
        <input
          className="peer block w-full rounded-md border text-black border-gray-200 py-[9px] pl-10 text-sm outline-2 placeholder:text-gray-500"
          {...register(fieldId, { required })}
          placeholder={placeholder || ""}
          disabled={disabled}
        />
        {icon}
      </div>
      {!!(error?.message) && <p role="alert" className="text-sm text-red-500 mt-4">{error?.message}</p>}
    </div>
  )
}

interface SelectFormItemProps {
  control: any;
  fieldName: string;
  fieldId: string;
  required?: boolean;
  placeholder?: string;
  containerClass?: string;
  error?: FieldError;
  disabled?: boolean;
  options: {
    label: string;
    value: string;
  }[]
}

export function SelectFormItem({
  control,
  fieldName,
  fieldId,
  required = false,
  placeholder,
  containerClass,
  error,
  disabled = false,
  options
}: SelectFormItemProps) {
  return (
    <div className={containerClass}>
      <label
        className="mb-3 mt-5 block text-xs font-medium text-gray-900"
        htmlFor={fieldId}
      >
        {fieldName}
        {required && (<span className="field-required">*</span>)}
      </label>
      <div className="relative">
        <Controller
          name={fieldId}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              isMulti
              isDisabled={disabled}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
                menu: (base) => ({
                  ...base,
                  color: 'black',
                }),
              }}
              className="form-style"
              placeholder={placeholder}
              options={options}
            />
          )}
        />
      </div>
      {!!(error?.message) && <p role="alert" className="text-sm text-red-500 mt-4">{error?.message}</p>}
    </div>
  )
}