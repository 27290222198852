import clsx from 'clsx';
import "./styles.scss";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  buttonType?: "default" | "outline" | "filled";
}

export function Button({
  children,
  className,
  buttonType = "default",
  ...rest
}: ButtonProps) {
  if (buttonType === "outline") {
    return (
      <button
        {...rest}
        className={clsx(
          'flex h-10 items-center bg-transparent hover:bg-blue-500 text-blue-700 font-bold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded-2xl',
          className,
        )}
      >
        {children}
      </button>
    );
  }


  if (buttonType === "filled") {
    return (
      <button
        {...rest}
        className={clsx(
          'flex h-10 items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded-2xl',
          className,
        )}
      >
        {children}
      </button>
    );
  }

  return (
    <button
      {...rest}
      className={clsx(
        'flex h-10 items-center rounded-lg bg-blue-500 px-4 text-sm font-medium text-white transition-colors hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 active:bg-blue-600 aria-disabled:cursor-not-allowed aria-disabled:opacity-50',
        className,
      )}
    >
      {children}
    </button>
  );
}

export function MainButton({
  children,
  className,
  ...rest
}: ButtonProps) {
  return (
    <button
      {...rest}
      className={clsx(
        'flex h-10 items-center btn-purple text-white py-2 px-4 border rounded-2xl',
        className,
      )}
    >
      {children}
    </button>
  )
}