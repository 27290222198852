
export default function AppLogo() {
  return (
    <div
      className="flex flex-row items-center leading-none text-white"
    >
      <p className="text-[44px] ">Learning Hub</p>
    </div>
  );
}
