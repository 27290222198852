import AirtableTable from "../AirtableTable";
import { Resource } from "../models";
import { AirtableQueryCondition } from "../types";

export class ResourceTable extends AirtableTable<Resource> {
  	constructor(instance: any) {
		super(instance, "Resources");

		this.fields = [
			"Name",
			"Resource Type",
			"Resource",
			"Courses",
			"Units",
			"Weeks",
			"Lessons",
			"Order",
			"Downloaded By"
		]
	}

	getAll = () => {
		return this.list({
			sort: [{
			  field: "Name",
			  direction: "asc"
			}]
		})
	}

	getByCourse = (course: string) => {
		let condition: AirtableQueryCondition = {
			filterByFormula: `{Courses} = "${course}"`,
			sort: [{
				field: "Name", direction: "asc"
			}]
		}

		return this.list(condition)
	}

	getByUnit = (unit: string) => {
		let condition: AirtableQueryCondition = {
			filterByFormula: `{Units} = "${unit}"`,
			sort: [{
				field: "Name", direction: "asc"
			}]
		}

		return this.list(condition)
	}

	getByWeek = (week: string) => {
		let condition: AirtableQueryCondition = {
			filterByFormula: `{Weeks} = "${week}"`,
			sort: [{
				field: "Name", direction: "asc"
			}]
		}

		return this.list(condition)
	}

	getByLesson = (lesson: string) => {
		let condition: AirtableQueryCondition = {
			filterByFormula: `{Lessons} = "${lesson}"`,
			sort: [{
				field: "Name", direction: "asc"
			}]
		}

		return this.list(condition)
	}

	getByUnitAndWeek = (unit: string, week: string) => {
		let condition: AirtableQueryCondition = {
			filterByFormula: `AND({Units} = "${unit}", {Weeks} = "${week}")`,
			sort: [{
				field: "Name", direction: "asc"
			}]
		}

		return this.list(condition)
	}

	getByUnitAndWeekAndLesson = (unit: string, week: string, lesson: string) => {
		let condition: AirtableQueryCondition = {
			filterByFormula: `AND({Units} = "${unit}", {Weeks} = "${week}", {Lessons} = "${lesson}")`,
			sort: [{
				field: "Name", direction: "asc"
			}]
		}

		return this.list(condition)
	}
}