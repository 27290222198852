import { Outlet } from "react-router-dom";
import { RequireAuth, useAuth } from "../../auth/AuthProvider";
import Footer from "../footer";
import Header from "../header";
import heroImg from "../../assets/images/hero.webp"
import { confirmAlert } from "react-confirm-alert";

export function MainLayout() {
  const { user, logout } = useAuth();

  const signOut = () => {
    confirmAlert({
      title: "Sign out",
      message: "Are you sure you want to sign out?",
      buttons: [{
        label: `OK`,
        onClick: () => logout()
      },
      {
        label: "Cancel",
        onClick: () => { }
      }]
    });
  }

  return (
    <RequireAuth>
      <div className="bg-gray-100 flex flex-col h-full">
        <Header />
        <div className="flex-1">
          <div className="hero-image">
            <img src={heroImg} alt="dwf dashboard hero" />
          </div>
          <div className="nav-bar">
            <div className="max-w-7xl h-full mx-auto flex justify-between items-center">
              <p>{`Welcome, ${user?.Name}!`}</p>
              <span
                onClick={() => signOut()}
                className="nav-link"
              >Sign out</span>
            </div>
          </div>
          <Outlet />
        </div>
        <Footer />
      </div>
    </RequireAuth>
  )
}