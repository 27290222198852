import AirtableTable from "../AirtableTable";
import { Teacher } from "../models";

export class TeacherTable extends AirtableTable<Teacher> {
  constructor(instance: any) {
    super(instance, "Teachers");

    this.fields = [
      "Name",
      "E-mail Address",
      "Pronouns",
      "Indigenous Group(s)",
      "Grade(s) Taught",
      "Subject(s) Taught",
      "In Salesforce?"
    ]
  }

  getTeacherByEmail = (email: string) => {
    const em = email.toUpperCase();
    return this.selectOneByCondition({
      filterByFormula: `"${em}" = UPPER({E-mail Address})`
    });
  }
}
