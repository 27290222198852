import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Announcement } from "../../lib/airtables/models";
import { setAnnouncements } from "../../redux/app/actions";
import airtable from "../../lib/airtables";
import { getNiceFormatDateString } from "../../helpers/time";

export default function AnnouncementsPage() {
  const { announcements } = useSelector((state: any) => state.app)
  const dispatch = useDispatch();

  useEffect(() => {
    if (!announcements || !announcements.length) {
      airtable.announcements.getItemsForToday().then(
        (res: Announcement[]) => {
          dispatch(setAnnouncements(res));
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <main className="dashboard-page page">
      <div className="max-w-7xl mx-auto my-12">
        <div className="announcements">
          {announcements.slice(0, 5).map((item: Announcement, i: number) => (
            <div
              key={i}
              className="flex flex-col py-2"
            >
              <div className="flex justify-between">
                <span className="text-base font-bold">{item.Title}</span>
                <span>{getNiceFormatDateString(new Date(item["Live Date"]))}</span>
              </div>
              <p>{item.Announcement}</p>
            </div>
          ))}
        </div>
      </div>
    </main>
  )
}