import { z } from "zod";

export const loginFormSchema = z.object({
  email: z
    .string()
    .min(1, {
      message: "This field has to be filled.",
    })
    .email("This is not a valid email")
    .max(300, {
      message: "Email can't be longer than 300 characters.",
    }),
});

export const registerFormSchema = z.object({
  email: z
    .string()
    .min(1, {
      message: "This field has to be filled.",
    })
    .email("This is not a valid email")
    .max(300, {
      message: "Email can't be longer than 300 characters.",
    }),
  name: z
    .string()
    .min(3, {
      message: "This field has to be filled.",
    })
    .max(256, {
      message: "Name can't be longer than 300 characters.",
    }),
  pronouns: z
    .string(),
  identify: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      })
    )
    .refine((options) => options.length > 0, {
      message: 'At least one option is required',
    }),
  grades: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      })
    )
    .refine((options) => options.length > 0, {
      message: 'At least one option is required',
    }),
  subjects: z
    .array(
      z.object({
        value: z.string(),
        label: z.string(),
      })
    )
    .refine((options) => options.length > 0, {
      message: 'At least one option is required',
    }),
})