import AirtableTable from "../AirtableTable";
import { Unit } from "../models";
import { AirtableQueryCondition } from "../types";

export class UnitTable extends AirtableTable<Unit> {
  	constructor(instance: any) {
		super(instance, "Units");

		this.fields = [
			"Unit",
			"Description",
			"Course",
			"Overview Document",
			"Downloaded By"
		]
	}

	getAll = () => {
		return this.list({
			sort: [{
			  field: "Unit",
			  direction: "asc"
			}]
		})
	}

	getByCourse = (course: string) => {
		let condition: AirtableQueryCondition = {
			filterByFormula: `{Course} = "${course}"`,
			sort: [{
				field: "Unit", direction: "asc"
			}]
		}

		return this.list(condition)
	}
}