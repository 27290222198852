import {
  AirtableParam,
  AirtableQueryCondition,
  AirtableRecord
} from "./types";

export default class AirtableTable<T> {
  instance: any;
  tableName: string;
  fields: string[];
  pageSize: number;
  table: any;

  constructor(instance: any, tableName: string) {
    this.instance = instance
    this.tableName = tableName;
    this.table = this.instance(this.tableName);
    this.fields = [];
    this.pageSize = 100;
  }

  create = (param: AirtableParam) => {
    return new Promise<T>((resolve, reject) => {
      this.table.create([{
        "fields": param
      }]).then((res: AirtableRecord[]) => {
        const record = res[0];
        resolve({
          ...record.fields,
          id: record.id
        } as T);
      }).catch((error: any) => {
        reject(error)
      })
    })
  }

  createMultiple = (paramList: AirtableParam[]) => {
    return new Promise<T[]>((resolve, reject) => {
      this.table.create(paramList.map(param => ({ fields: param })))
        .then((res: AirtableRecord[]) => {
          const records = res.map(item => ({
            ...item.fields,
            id: item.id
          } as T))
          resolve(records);
        }).catch((error: any) => {
          reject(error)
        })
    })
  }

  select = (id: string) => {
    if (!id) return null;

    return new Promise<T | null>((resolve, reject) => {

      this.table.find(id).then((record: AirtableRecord) => {
        if (record && record._rawJson) {
          resolve({
            ...record.fields,
            id: record.id
          } as T)
        } else {
          resolve(null)
        }
      }).catch((error: any) => {
        if (error.error === 'NOT_FOUND') resolve(null);
        else reject(error.toString())
      })
    })
  }

  delete = (id: string) => {
    if (!id) return;
    return new Promise<T>((resolve, reject) => {
      this.table.destroy([id]).then((res: AirtableRecord) => {
        resolve({
          ...res.fields,
          id: res.id
        } as T)
      }).catch((error: any) => {
        reject(error)
      })
    })
  }

  update = (id: string, param: AirtableParam) => {
    if (!id || !param || Object.values(param).length === 0) return;

    return new Promise<T>((resolve, reject) => {
      this.table.update([{
        id, fields: param
      }]).then((res: AirtableRecord[]) => {
        const record = res[0];
        resolve({
          ...record.fields,
          id: record.id
        } as T)
      }).catch((error: any) => {
        reject(error)
      })
    })
  }

  multipleUpdate = (infoList: {
    id: string;
    param: AirtableParam
  }[]) => {
    return new Promise<T[]>((resolve, reject) => {
      this.table.update(infoList.map(info => ({
        id: info.id,
        fields: info.param
      }))).then((res: AirtableRecord[]) => {
        resolve(res.map(item => ({
          ...item.fields,
          id: item.id
        } as T)))
      }).catch((error: any) => {
        reject(error)
      })
    })
  }

  list = (
    condition: AirtableQueryCondition,
    filter?: Function,
    allFields?: boolean
  ) => {
    return new Promise<T[]>((resolve, reject) => {
      const result: T[] = [];

      let con = { ...condition };
      if (!condition.fields && this.fields && !allFields) {
        con = {
          ...condition,
          fields: this.fields
        }
      }

      this.table.select(con).eachPage((records: AirtableRecord[], fetchNextPage: Function) => {
        records.forEach(record => {
          var item: T = {
            id: record.id,
            ...record.fields
          } as T;
          if (!filter || filter(item)) {
            result.push(item);
          }
        })
        fetchNextPage()
      }).then(() => {
        resolve(result)
      }).catch((error: any) => {
        reject(error)
      })
    });
  }

  listByIds = (ids: string[], extraCondition?: AirtableQueryCondition) => {
    const filter = ids.map(id => `RECORD_ID() = '${id}'`).join(',');
    let condition: AirtableQueryCondition = { 
      ...(extraCondition || {}),
      filterByFormula: `OR(${filter})` 
    };

    if (this.fields) condition.fields = this.fields;
    return this.list(condition)
  }

  selectOneByCondition = async (condition: AirtableQueryCondition) => {
    const items: any[] = await this.list({
      ...condition, maxRecords: 1
    }, undefined, true);

    return (items && items.length) ? items[0] : null;
  }

  getFirstPage = (condition: AirtableQueryCondition) => {
    return new Promise<T[]>((resolve, reject) => {
      var result: T[] = [];
      let con = { ...condition };
      if (!condition.fields && this.fields) {
        con = {
          ...condition,
          fields: this.fields
        }
      }

      this.table.select(con)
        .firstPage()
        .then((records: AirtableRecord[]) => {
          records.forEach(record => {
            var item = {
              id: record.id,
              ...record.fields
            } as T
            result.push(item);
          })
          resolve(result)
        }).catch((error: any) => {
          reject(error)
        })
    });
  }

  listAll = () => {
    let condition: AirtableQueryCondition = {}
    if (this.fields) condition.fields = this.fields;
    return this.list(condition);
  }

  /**
   * 
   * @typedef ConditionProp
   * @property {string} filterByFormula
   * @property {Array<Object>} sort
   * @property {number} pageSize
   * 
   * @param {ConditionProp} condition 
   * @param {*} loadFunc 
   * @returns 
   */
  listItems = (condition: AirtableQueryCondition, loadFunc: Function) => {
    return new Promise((resolve, reject) => {

      let pageNum = 0;
      const result: {
        pageNo: number;
        items: T[];
      }[] = [];
      const totalResult: T[] = [];

      let con = { ...condition };
      if (!condition.fields && this.fields) {
        con = {
          ...condition,
          fields: this.fields
        }
      }

      if (!con.pageSize) con.pageSize = this.pageSize;

      this.table.select(con).eachPage((records: AirtableRecord[], fetchNextPage: Function) => {
        const pageItems: T[] = [];
        records.forEach(record => {
          var item = {
            id: record.id,
            ...record.fields
          } as T;
          pageItems.push(item);
          totalResult.push(item);
        });

        const newPage = {
          pageNo: pageNum,
          items: pageItems
        }
        result.push(newPage);
        loadFunc && loadFunc([...result]);
        pageNum++;

        fetchNextPage()
      }).then(() => {
        resolve(totalResult)
      }).catch((error: any) => {
        reject(error)
      })
    });
  }
}