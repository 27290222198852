export const getSession = (sessionId: string) => {
  var sess = window.localStorage.getItem(sessionId);
  if (sess) {
    return JSON.parse(sess)
  } else {
    return null;
  }
}

export const setSession = (sessionId: string, data: any) => {
  window.localStorage.setItem(sessionId, JSON.stringify(data));
  return true;
}

export const removeSession = (sessionId: string) => {
  window.localStorage.removeItem(sessionId);
  return true;
}

export const removeAllSession = async () => {
  window.localStorage.clear();
}