import AirtableBase from "./AirtableBase";
import {
	AnnouncementTable,
	CalendarTable,
	SubjectTable,
	TeacherTable,
	CourseTable,
	UnitTable,
	WeekTable,
	LessonTable,
	ResourceTable
} from "./tables";

export class Airtable extends AirtableBase {
	teachers: TeacherTable;
	subjects: SubjectTable;
	calendars: CalendarTable;
	announcements: AnnouncementTable;
	courses: CourseTable;
	units: UnitTable;
	weeks: WeekTable;
	lessons: LessonTable;
	resources: ResourceTable;

	constructor(apiKey: string, base: string) {
		super(apiKey, base);

		this.teachers = new TeacherTable(this.instance);
		this.subjects = new SubjectTable(this.instance);
		this.calendars = new CalendarTable(this.instance);
		this.announcements = new AnnouncementTable(this.instance);
		this.courses = new CourseTable(this.instance);
		this.units = new UnitTable(this.instance);
		this.weeks = new WeekTable(this.instance);
		this.lessons = new LessonTable(this.instance);
		this.resources = new ResourceTable(this.instance);
	}
}

const AIRTABLE_APP = "apprWesJGBmsmfsWx";

export const AIRTABLE_KEY = "patjBlK5mf2a5E5PI.6ef674685bc260cb886c80ce374aaa028d91cc9ec8ddb1c1cfac39fe3c7d4ea2";

const airtableInstance: Airtable = new Airtable(AIRTABLE_KEY, AIRTABLE_APP);

export default airtableInstance;
