import { Announcement, Subject } from "../../lib/airtables/models";
import {
  SET_APP_USERINFO,
  SET_TOTAL_SUBJECTS,
  SET_ANNOUNCEMENTS,
} from "./types";

interface StatePros {
  userInfo: any;
  totalSubjects: Subject[];
  announcements: Announcement[];
}

const initialState: StatePros = {
  userInfo: null,
  totalSubjects: [],
  announcements: [],
}

const appReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_APP_USERINFO:
      return {
        ...state,
        userInfo: action.payload
      }
    case SET_TOTAL_SUBJECTS:
      return {
        ...state,
        totalSubjects: action.payload
      }
    case SET_ANNOUNCEMENTS:
      return {
        ...state,
        announcements: action.payload
      }

    default:
      return state;
  }
}

export default appReducer;