import { FC } from "react";
import { Announcement } from "../../../lib/airtables/models";
import { useNavigate } from "react-router-dom";
import { getNiceFormatDateString } from "../../../helpers/time";

interface AnnouncementsProps {
  data: Announcement[];
}

const Announcements: FC<AnnouncementsProps> = ({ data }) => {
  const navigate = useNavigate();

  return (
    <section className="upcoming-announcements mt-8">
      <div className="section-title my-2">
        <span className="text-xl font-bold">Announcements</span>
      </div>

      <div className="announcements">
        {data.slice(0, 5).map((item, i) => (
          <div
            key={i}
            className="flex flex-col py-2"
          >
            <div className="flex justify-between">
              <span className="text-base font-bold">{item.Title}</span>
              <span>{getNiceFormatDateString(new Date(item["Live Date"]))}</span>
            </div>
            <p>{item.Announcement}</p>
          </div>
        ))}
      </div>
      {data.length > 5 && (
        <div className="flex justify-end">
          <span
            className="btn-more"
            onClick={() => navigate("/app/announcements")}
          >View more...</span>
        </div>
      )}
    </section>
  )
}

export default Announcements;