import { z } from "zod";
import { AtSymbolIcon } from "@heroicons/react/24/outline";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { Button } from "../buttons/button";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import FormItem from "../FormItem";
import { loginFormSchema } from "../../lib/formSchemas";
import { useAuth } from "../../auth/AuthProvider";
import { useNavigate } from "react-router-dom";

export default function LoginForm() {
  const form = useForm<z.infer<typeof loginFormSchema>>({
    resolver: zodResolver(loginFormSchema),
    defaultValues: {},
  });
  const { register, formState: { errors }, handleSubmit } = form;
  const [isPending, setIsPending] = useState(false);
  let auth = useAuth();
  let navigate = useNavigate();

  async function onSubmit(values: z.infer<typeof loginFormSchema>) {
    setIsPending(true);
    const res = await auth.loginUser(values.email);
    if (res) {
      if (res.error) {
        navigate("/user-error");
      } else if (res.user) {
        if (res.type === "register") {
          navigate("/login-error")
        } else {
          navigate("/app/dashboard")
        }
      }
    }
    setIsPending(false);
  }

  return (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-3"
      >
        <div className="flex-1 rounded-lg bg-gray-50 px-6 pb-4 pt-8">
          <div className="w-full">
            <FormItem
              register={register}
              fieldName="E-mail Address"
              fieldId="email"
              required={true}
              disabled={isPending}
              placeholder="Enter your email address"
              icon={(<AtSymbolIcon className="pointer-events-none absolute left-3 top-1/2 h-[18px] w-[18px] -translate-y-1/2 text-gray-500 peer-focus:text-gray-900" />)}
              error={errors?.email}
            />
          </div>
          <Button className="mt-4 w-full" aria-disabled={isPending}>
            Log in <ArrowRightIcon className="ml-auto h-5 w-5 text-gray-50" />
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
