import AirtableTable from "../AirtableTable";
import { Subject } from "../models";

export class SubjectTable extends AirtableTable<Subject> {
  constructor(instance: any) {
    super(instance, "Subjects");

    this.fields = [
      "Subject",
      "Subject (FR)",
      "Curriculum Links",
      "Teachers",
      "Courses"
    ]
  }

  getTotalSubjects = () => {
    return this.list({
      sort: [{
        field: "Subject",
        direction: "asc"
      }]
    })
  }
}
