export const DWF_LEARNING_USERID = 'DWF_LEARNING_USERID';

export const AvailableGrades = [
  "JK",
  "K",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12"
]

export const IndigenousGroup = [
  "First Nations",
  "Inuit",
  "Métis",
  "None of the Above"
]
