import AirtableTable from "../AirtableTable";
import { Week } from "../models";
import { AirtableQueryCondition } from "../types";

export class WeekTable extends AirtableTable<Week> {
  	constructor(instance: any) {
		super(instance, "Weeks");

		this.fields = [
			"Week",
			"Course",
			"Unit",
			"Week Powerpoint",
			"Downloaded By"
		]
	}

	getAll = () => {
		return this.list({
			sort: [{
			  field: "Week",
			  direction: "asc"
			}]
		})
	}

	getByCourse = (course: string) => {
		let condition: AirtableQueryCondition = {
			filterByFormula: `{Course} = "${course}"`,
			sort: [{
				field: "Week", direction: "asc"
			}]
		}

		return this.list(condition)
	}

	getByUnit = (unit: string) => {
		let condition: AirtableQueryCondition = {
			filterByFormula: `{Unit} = "${unit}"`,
			sort: [{
				field: "Week", direction: "asc"
			}]
		}

		return this.list(condition)
	}
}