import { createStore, combineReducers } from "redux";
import appReducer from "./app/reducer";
import authReducer from "./auth/reducer";
import { TypedUseSelectorHook, useSelector } from "react-redux";

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
})

const configureStore = () => {
  return createStore(rootReducer);
}

const store = configureStore();

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;