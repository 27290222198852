import AirtableTable from "../AirtableTable";
import { Course } from "../models";

export class CourseTable extends AirtableTable<Course> {
  	constructor(instance: any) {
		super(instance, "Courses");

		this.fields = [
			"Course",
			"Subjects",
			"Description",
			"Grade",
			"Course Overview Document",
			"Downloaded By"
		]
	}

	getAll = () => {
		return this.list({
			sort: [{
			  field: "Course",
			  direction: "asc"
			}]
		})
	}
}
