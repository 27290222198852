export const contentApi = (email: string) => {
  const apiUrl = `https://hooks.zapier.com/hooks/catch/20058035/2dy9mej/?email=${email}`;

  return new Promise((resolve, reject) => {
    fetch(apiUrl, {
      method: 'GET'
    })
      .then(response => {
        resolve(true)
      })
      .catch(error => {
        resolve(false)
      });
  })
}
