import "./App.scss";
import { Route, Routes, Navigate } from "react-router-dom";
import AuthProvider from "./auth/AuthProvider";
import { Layout, MainLayout } from "./components/layouts";
import LoginPage from "./pages/login";
import LoginErrorPage from "./pages/login/error";
import ContentErrorPage from "./pages/login/content-error";
import RegisterPage from "./pages/register";
import DashboardPage from "./pages/dashboard";
import WelcomePage from "./pages/welcome";
import { useEffect } from "react";
import airtable from "./lib/airtables";
import { useDispatch } from "react-redux";
import { setTotalSubjects } from "./redux/app/actions";
import "react-confirm-alert/src/react-confirm-alert.css";
import AnnouncementsPage from "./pages/announcements";
import CoursesPage from "./pages/courses";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    airtable.subjects.getTotalSubjects().then(res => {
      dispatch(setTotalSubjects(res));
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="app/dashboard" replace />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="login-error" element={<LoginErrorPage />} />
          <Route path="user-error" element={<ContentErrorPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="welcome" element={<WelcomePage />} />

          <Route path="app" element={<MainLayout />}>
            <Route index element={<Navigate to="dashboard" replace />} />
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="announcements" element={<AnnouncementsPage />} />
            <Route path="courses" element={<CoursesPage />} />
          </Route>
        </Route>
      </Routes>
    </AuthProvider>
  );
}

export default App;
