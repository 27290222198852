import AirtableTable from "../AirtableTable";
import { Announcement } from "../models";
import { AirtableQueryCondition } from "../types";

export class AnnouncementTable extends AirtableTable<Announcement> {
  constructor(instance: any) {
    super(instance, "Announcements");

    this.fields = [
      "Title",
      "Announcement",
      "Live Date"
    ]
  }

  getItemsForToday = (limit?: number) => {
    let condition: AirtableQueryCondition = {
      filterByFormula: "DATESTR({Live Date}) <= DATESTR(NOW())",
      sort: [{
        field: "Live Date", direction: "desc"
      }]
    }

    if (limit) condition.maxRecords = limit;
    return this.list(condition)
  }

  getUpcomings = (limit?: number) => {
    let condition: AirtableQueryCondition = {
      filterByFormula: "{Live Date} >= NOW()",
      sort: [{
        field: "Live Date", direction: "desc"
      }]
    }

    if (limit) condition.maxRecords = limit;
    return this.list(condition)
  }
}
