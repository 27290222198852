import Calendars from "./sections/calendar";
import Facebook from "./sections/facebook";
import Announcements from "./sections/announcements";
import useDashboard from "../../lib/hooks/use-dashboard";
import { Link } from "react-router-dom";

export default function DashboardPage() {
  const {
    calendars,
    announcements,
    courses
  } = useDashboard();

  return (
    <main className="dashboard-page page">
      <div className="max-w-7xl mx-auto my-12">
        <div className="flex">
          <div className="w-8/12 p-6">
            <div className="p-6">
              <Link to="/app/courses" className="btn btn-primary">View all courses ({courses.length})</Link>
            </div>
            <div className="p-6">
              <Announcements data={announcements} />
            </div>
          </div>
          <div className="w-4/12 p-6">
            <Facebook
              pageUrl="https://www.facebook.com/downiewenjack"
              name="Downie Wenjack Fund"
            />
            <Calendars data={calendars} />
          </div>
        </div>
      </div>
    </main>
  )
}