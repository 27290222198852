import spinnerImg from "../assets/images/spinner.png";

interface LoadingProps {
	size: number;
}

export const Loading = ({ size }: LoadingProps) => (
	<img
		src={spinnerImg}
		className="op-loading"
		style={{ width: size, height: size, margin: "0 5px" }}
		alt=""
	/>
);

export default Loading;
