import React from "react";
import useCourses from "../../lib/hooks/use-courses";
import airtable from "../../lib/airtables";
import "./accordion.css";

interface AccordionItemProps {
  title: string;
  children: React.ReactNode;
}

const AccordionItem: React.FC<AccordionItemProps> = React.memo(({ title, children }) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const toggleIsOpen = React.useCallback(() => {
		setIsOpen(prevIsOpen => !prevIsOpen);
	  }, []);
  
	return (
	  <div className="accordion-item">
		<div className="accordion-title" onClick={toggleIsOpen}>
		  {title}
		</div>
		{isOpen && <div className="accordion-content">{children}</div>}
	  </div>
	);
});

export default function CoursesPage() {
	const { user, courses, units, weeks, lessons, resources } = useCourses();

	const getUnitsForCourse = React.useCallback((courseId: string) => {
		return units
			.filter(unit => 
				Array.isArray(unit?.Course) && unit.Course.includes(courseId)
			)
			.sort((a, b) => a.Unit.localeCompare(b.Unit));
	}, [units]);

	const getWeeksForUnit = React.useCallback((unitId: string) => {
		return weeks
		  .filter(week => 
			Array.isArray(week?.Unit) && week.Unit.includes(unitId)
		  )
		  .sort((a, b) => a.Week.localeCompare(b.Week));
	}, [weeks]);
  
	const getLessonsForWeek = React.useCallback((weekId: string) => {
	  return lessons
		.filter(lesson => 
		  Array.isArray(lesson?.Weeks) && lesson.Weeks.includes(weekId)
		)
		.sort((a, b) => a.Lesson.localeCompare(b.Lesson));
	}, [lessons]);
	
	const getResourcesForLesson = React.useCallback((lessonId: string) => {
	  return resources
		.filter(resource => 
		  Array.isArray(resource?.Lessons) && resource.Lessons.includes(lessonId)
		)
		.sort((a, b) => a.Name.localeCompare(b.Name));
	}, [resources]);

  // Define the clickHandler function
  const clickHandler = React.useCallback((table: string, field: string, recordId: string, oldUsers: Array<string>, userId: string) => {
	// Define the updatedUsers variable
	let updatedUsers;
	// Check if oldUsers is an array and has data
	if (Array.isArray(oldUsers) && oldUsers.length > 0) {
	  // Add userId to the oldUsers array and remove duplicates
	  updatedUsers = Array.from(new Set([...oldUsers, userId]));
	} else {
	  updatedUsers = [userId];
	}

	// Update the record in Airtable
	let update_details = {
		[field]: updatedUsers
	};

	let t: any = undefined;

	const updateRecord = async () => {
		if (table === 'Courses') {
			t = await airtable.courses.update(recordId, update_details);
		} else if (table === 'Units') {
			t = await airtable.units.update(recordId, update_details);
		} else if (table === 'Weeks') {
			t = await airtable.weeks.update(recordId, update_details);
		} else if (table === 'Resources') {
			t = await airtable.resources.update(recordId, update_details);
		}

		console.log('Record updated:', t);
	};

	updateRecord();
  }, []);

	return (
		<main className="courses-page page">
			<div className="max-w-7xl mx-auto my-12">
				<h1 className="text-3xl font-bold mb-6">Courses</h1>
				<p>Welcome to the Courses page. Here you will find a list of all available courses.</p>
				{/* Add more content or components related to courses here */}
				{courses.map((course, i) => (
					<AccordionItem key={course.id} title={course.Course}>
						<div>
							{course.Description && (
								<p>
									{course.Description}
								</p>
							)}
							{course['Course Overview Document'] && (
								<p>
									<a 
										href={course['Course Overview Document'][0]['url']} 
										rel="noopener noreferrer" 
										className='App-link' 
										onClick={() => course.id && clickHandler('Courses', 'Downloaded By', course.id, course['Downloaded By'], user.id)} >
										View Course Overview Document
									</a>
								</p>
							)}
						</div>
						{course.id && getUnitsForCourse(course.id).map(unit => (
							<AccordionItem key={unit.id} title={unit.Unit}>
								<div>
									{unit.Description && (
										<p>
											{unit.Description}
										</p>
									)}
									{unit['Overview Document'] && (
										<p>
											<a 
												href={unit['Overview Document'][0]['url']} 
												rel="noopener noreferrer" 
												className='App-link' 
												onClick={() => unit.id && clickHandler('Units', 'Downloaded By', unit.id, unit['Downloaded By'], user.id)}>
												View Unit Overview Document
											</a>
										</p>
									)}
								</div>
								{unit.id && getWeeksForUnit(unit.id).map(week => (
									<AccordionItem key={week.id} title={week.Week}>
										<div>
											{week['Week Powerpoint'] && (
												<p>
													<a 
														href={week['Week Powerpoint'][0]['url']} 
														rel="noopener noreferrer" 
														className='App-link' 
														onClick={() => week.id && clickHandler('Weeks', 'Downloaded By', week.id, week['Downloaded By'], user.id)}>
														View Weekly Powerpoint
													</a>
												</p>
											)}
										</div>
										{week.id && getLessonsForWeek(week.id).map(lesson => (
											<AccordionItem key={lesson.id} title={lesson.Lesson}>
												 <ul>
													{lesson.id && getResourcesForLesson(lesson.id).map(resource => (
														<li key={resource.id}>
															<a 
																href={resource.Resource && resource.Resource[0]['url']} 
																rel="noopener noreferrer" 
																className={resource.Resource && 'App-link'}
																onClick={() => resource.id && clickHandler('Resources', 'Downloaded By', resource.id, resource['Downloaded By'], user.id)}>
																{resource.Name}
															</a>
														</li>
													))}
												</ul>
											</AccordionItem>
										))}
									</AccordionItem>
								))}
							</AccordionItem>
						))}
					</AccordionItem>
				))}
			</div>
		</main>
	);
}
