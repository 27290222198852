import { useEffect, useState } from "react";
import airtable from "../airtables";
import { Course, Unit, Week, Lesson, Resource } from "../airtables/models";
import { useAuth } from "../../auth/AuthProvider";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";

export default function useCourses() {
	const [courses, setCourses] = useState<Course[]>([]);
	const [units, setUnits] = useState<Unit[]>([]);
	const [weeks, setWeeks] = useState<Week[]>([]);
	const [lessons, setLessons] = useState<Lesson[]>([]);
	const [resources, setResources] = useState<Resource[]>([]);
	const auth = useAuth();
	const dispatch = useDispatch();

	const signOut = () => {
		confirmAlert({
		title: "Sign out",
		message: "Are you sure you want to sign out?",
		buttons: [{
			label: `OK`,
			onClick: () => auth.logout()
		},
		{
			label: "Cancel",
			onClick: () => { }
		}]
		});
	}

	useEffect(() => {
		airtable.courses.getAll().then(
			(res: Course[]) => {
				setCourses(res)
			}
		)

		airtable.units.getAll().then(
			(res: Unit[]) => {
				setUnits(res)
			}
		)

		airtable.weeks.getAll().then(
			(res: Week[]) => {
				setWeeks(res)
			}
		)

		airtable.lessons.getAll().then(
			(res: Lesson[]) => {
				setLessons(res)
			}
		)

		airtable.resources.getAll().then(
			(res: Resource[]) => {
				setResources(res)
			}
		)
	}, [dispatch])

	return {
		user: auth.user,
		courses,
		units,
		weeks,
		lessons,
		resources,
		signOut
	}
}