import {
  SET_USERNAME,
  SET_SCHOOL,
  SET_TEACHERS,
  SET_GRADE,
  SET_PIN
} from "./types";

interface StateProps {
  username: string;
  school: any;
  teachers: any[];
  grade: string;
  pin: string;
}

const initialState: StateProps = {
  username: "",
  school: null,
  teachers: [],
  grade: "",
  pin: ""
}

const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_USERNAME:
      return {
        ...state,
        username: action.payload
      }

    case SET_SCHOOL:
      return {
        ...state,
        school: action.payload
      }

    case SET_TEACHERS:
      return {
        ...state,
        teachers: action.payload
      }

    case SET_GRADE:
      return {
        ...state,
        grade: action.payload
      }

    case SET_PIN:
      return {
        ...state,
        pin: action.payload
      }

    default:
      return state;
  }
}

export default authReducer;