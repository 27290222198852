import AppLogo from "../components/AppLogo";
import RegisterForm from "../components/forms/register";

export default function RegisterPage() {
  return (
    <main className="flex items-center justify-center md:h-screen">
      <div className="relative mx-auto flex w-full max-w-[640px] flex-col space-y-2.5 p-4 md:-mt-32">
        <div className="flex h-16 w-full items-end rounded-lg bg-blue-500 p-3 md:h-24">
          <div className="text-white">
            <AppLogo />
          </div>
        </div>
        <RegisterForm />
      </div>
    </main>
  )
}