import { 
  Announcement,
  Subject, 
  Teacher 
} from "../../lib/airtables/models"
import {
  SET_APP_USERINFO,
  SET_TOTAL_SUBJECTS,
  SET_ANNOUNCEMENTS,
} from "./types"

export const setAppUserInfo = (ui: Teacher) => {
  return {
    type: SET_APP_USERINFO,
    payload: ui
  }
}

export const setTotalSubjects = (ts: Subject[]) => {
  return {
    type: SET_TOTAL_SUBJECTS,
    payload: ts
  }
}

export const setAnnouncements = (as: Announcement[]) => {
  return {
    type: SET_ANNOUNCEMENTS,
    payload: as
  }
}