import {
  MainButton
} from "../buttons/button";
import logoImg from "../../assets/images/footerlogo.png";

export default function Footer() {
  const gotoNewPage = (url: string) => {
    const newTab = window.open(url, "_blank");
    newTab && newTab.focus();
  }

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto my-12 py-12">
        <div className="flex gap-x-8">
          <div className="flex-1">
            <div className="">
              <img src={logoImg} alt="footer logo" />
            </div>
            <p className="text-base mt-2">The Gord Downie & Chanie Wenjack Fund</p>
            <p className="text-base mt-1">Registered Charity Number: 784055915RR0001</p>
            <MainButton
              className="mt-4"
              onClick={() => gotoNewPage("/Privacy-Policy.pdf")}
            >Privary Policy</MainButton>
          </div>

          <div className="flex-1">
            <h4 className="mb-4 text-xl font-extrabold md:text-xl lg:text-2xl">About DWF</h4>
            <p className="text-base">Inspired by Chanie’s story and Gord’s call to build a better Canada, the Gord Downie & Chanie Wenjack Fund aims to build cultural understanding and create a path toward reconciliation between Indigenous and non-Indigenous peoples. Our goal is to improve the lives of Indigenous people by building awareness, education, and connections between all peoples in Canada.</p>
            <MainButton
              className="mt-4"
              onClick={() => gotoNewPage("https://downiewenjack.ca/news-publications/subscribe/")}
            >Subscribe to our Newsletter</MainButton>
          </div>

          <div className="flex-1 flex flex-col">
            <h4 className="mb-4 text-xl font-extrabold md:text-xl lg:text-2xl">Get in touch</h4>
            <span className="text-base mt-1">E-mail: <a href="mailto:staff@downiewenjack.ca">staff@downiewenjack.ca</a></span>
            <span className="text-base mt-1">Toll-free: 1-833-666-2414</span>
            <span className="text-base mt-1">Local: 519-964-0393</span>
            <span className="text-base mt-1" style={{ whiteSpace: "pre" }}>Address: <br />{`PO BOX 749\nOHSWEKEN, ON\nN0A1M0`}</span>
          </div>
        </div>
      </div>
    </div>
  )
}