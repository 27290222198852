const Airtable = require('airtable');

export default class AirtableBase {
    apiKey: string;
    base: string;
    instance: any;

    constructor(apiKey: string, base: string) {
        this.apiKey = apiKey;
        this.base = base;

        this.instance = new Airtable({ apiKey: this.apiKey }).base(this.base);
    }
}