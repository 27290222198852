import { useEffect, useState } from "react";
import airtable from "../airtables";
import { Announcement, Calendar, Course } from "../airtables/models";
import { useAuth } from "../../auth/AuthProvider";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { setAnnouncements as setTotalAnnouncements } from "../../redux/app/actions";

export default function useDashboard() {
  const [calendars, setCalendars] = useState<Calendar[]>([]);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [courses, setCourses] = useState<Course[]>([]);
  const auth = useAuth();
  const dispatch = useDispatch();

  const signOut = () => {
    confirmAlert({
      title: "Sign out",
      message: "Are you sure you want to sign out?",
      buttons: [{
        label: `OK`,
        onClick: () => auth.logout()
      },
      {
        label: "Cancel",
        onClick: () => { }
      }]
    });
  }

  useEffect(() => {
    airtable.calendars.getUpcomings().then(
      (res: Calendar[]) => {
        setCalendars(res)
      }
    )

    airtable.announcements.getItemsForToday(6).then(
      (res: Announcement[]) => {
        setAnnouncements(res);
        dispatch(setTotalAnnouncements(res))
      }
    )

	airtable.courses.getAll().then(
	  (res: Course[]) => {
		setCourses(res)
	  }
	)
  }, [dispatch])

  return {
    user: auth.user,
    calendars,
    announcements,
	courses,
    signOut
  }
}