import styled from "styled-components";

export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  z-index: 100;
  background: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
`

export const LogoContainer = styled.div`
  width: 50px;
  height: 50px;
`

export const NavItem = styled.span`
  cursor: pointer;
  color: var(--primary);
`