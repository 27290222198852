import { useNavigate } from "react-router-dom";
import { Button } from "../components/buttons/button";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

export default function WelcomePage() {
  let navigate = useNavigate();

  return (
    <main className="flex items-center justify-center md:h-screen">
      <div className="relative mx-auto flex w-full max-w-[1040px] flex-col space-y-2.5 p-4 md:-mt-32">
        <div className="flex-1 rounded-lg bg-gray-50 px-6 pb-8 pt-8">
          <section className="text-center">
            <h3 className="mb-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-4xl">Welcome to this Platform!</h3>
            <p className="mb-6 text-lg font-normal text-gray-500 dark:text-gray-400">Thanks for sharing information about your teaching focus and reviewing our policies. We&apos;re excited to serve your students with your first session soon! We invite you to watch this short video welcome from our team;</p>
            <div className="flex max-w-[480px] m-auto justify-center">
              <Button
                className="h-12"
                buttonType="filled"
                onClick={() => navigate("/app/dashboard")}
              >
                Start using this platform&nbsp;&nbsp;<ChevronRightIcon className="ml-auto h-5 w-5 text-white " />
              </Button>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
}