import { useEffect, useState } from "react";
import { getSession, removeSession, setSession } from "../helpers/storage";
import { useLocation, useNavigate } from "react-router-dom";
import { DWF_LEARNING_USERID } from "../helpers/constants";
import airtable from "../lib/airtables";
import { z } from "zod";
import { registerFormSchema } from "../lib/formSchemas";
import { contentApi } from "../lib/apis";
import { waitDelay } from "../helpers/time";
import { hideLoading, showLoading } from "../helpers/loading";

export const useAuthProvider = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [triedEmail, setTriedEmail] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const userId = getSession(DWF_LEARNING_USERID);
    if (!userId) {
      setUser(null);
      setLoading(false);
      navigate("/login", { replace: true })
      return;
    }

    airtable.teachers.select(userId)?.then((res: any) => {
      setUser(res);
      setLoading(false);
      if (
        location.pathname === '/' ||
        location.pathname.startsWith('/login')
      ) {
        navigate("/app/dashboard", { replace: true })
      }
    }).catch(() => {
      setLoading(false);
      navigate("/login", { replace: true })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getReturnUser = (user: any) => {
    if (user["Name"]) {
      setUser(user);
      setSession(DWF_LEARNING_USERID, user.id);
      return { user };
    } else {
      setTriedEmail(user["E-mail Address"]);
      setUser(null);
      return { user, type: "register" }
    }
  }

  const loginUser = async (email: string) => {
    if (!email) return;

    showLoading("Verifying user...");
    let user = await airtable.teachers.getTeacherByEmail(email);
    if (!user) {
      await airtable.teachers.create({ "E-mail Address": email });
      contentApi(email);
      await waitDelay(10000);
      user = await airtable.teachers.getTeacherByEmail(email);
      
      hideLoading();
      if (user["In Salesforce?"] === "Yes") {
        return getReturnUser(user);
      } else {
        return { error: "content" }
      }
    }

    if (user["In Salesforce?"] === "Yes") {
      hideLoading();
      return getReturnUser(user);
    }

    contentApi(email);
    await waitDelay(10000);
    user = await airtable.teachers.getTeacherByEmail(email);
    
    hideLoading();
    if (user["In Salesforce?"] === "Yes") {
      return getReturnUser(user)
    }
    setUser(null);
    return { error: "content" }
  }

  const registerUser = async (values: z.infer<typeof registerFormSchema>) => {
    let user = await airtable.teachers.getTeacherByEmail(values.email);

    user = await airtable.teachers.update(user.id, {
      Name: values.name,
      Pronouns: values.pronouns,
      "Indigenous Group(s)": values.identify.map(item => item.value),
      "Grade(s) Taught": values.grades.map(item => item.value),
      "Subject(s) Taught": values.subjects.map(item => item.value),
    });

    setUser(user);
    setTriedEmail("");
    setSession(DWF_LEARNING_USERID, user.id);
    return { user: user };
  }

  const logout = () => {
    setUser(null);
    removeSession(DWF_LEARNING_USERID);
    navigate("/login")
  }

  return {
    loading,
    user,
    triedEmail,
    loginUser,
    registerUser,
    logout
  }
}