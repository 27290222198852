import styled from 'styled-components';
import Loading from './Loading';

export const PageLoading = () => {
  return (
    <Container>
      <Loading size={40} />
    </Container>
  )
}

export default PageLoading;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`