import AirtableTable from "../AirtableTable";
import { Lesson } from "../models";
import { AirtableQueryCondition } from "../types";

export class LessonTable extends AirtableTable<Lesson> {
  	constructor(instance: any) {
		super(instance, "Lessons");

		this.fields = [
			"Lesson",
			"Courses",
			"Units",
			"Weeks",
			"Resources"
		]
	}

	getAll = () => {
		return this.list({
			sort: [{
			  field: "Lesson",
			  direction: "asc"
			}]
		})
	}

	getByCourse = (course: string) => {
		let condition: AirtableQueryCondition = {
			filterByFormula: `{Courses} = "${course}"`,
			sort: [{
				field: "Lesson", direction: "asc"
			}]
		}

		return this.list(condition)
	}

	getByUnit = (unit: string) => {
		let condition: AirtableQueryCondition = {
			filterByFormula: `{Units} = "${unit}"`,
			sort: [{
				field: "Lesson", direction: "asc"
			}]
		}

		return this.list(condition)
	}

	getByWeek = (week: string) => {
		let condition: AirtableQueryCondition = {
			filterByFormula: `{Weeks} = "${week}"`,
			sort: [{
				field: "Lesson", direction: "asc"
			}]
		}

		return this.list(condition)
	}

	getByUnitAndWeek = (unit: string, week: string) => {
		let condition: AirtableQueryCondition = {
			filterByFormula: `AND({Units} = "${unit}", {Weeks} = "${week}")`,
			sort: [{
				field: "Lesson", direction: "asc"
			}]
		}

		return this.list(condition)
	}
}