import { useEffect, useRef } from "react";

const Facebook = ({ pageUrl, name }: { pageUrl: string, name: string }) => {
  const scriptRef = useRef<HTMLScriptElement | null>(null);

  useEffect(() => {
    const script = document.createElement("script") as HTMLScriptElement;
    script.src = "https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v20.0";
    script.async = true;
    script.crossOrigin="anonymous";
    script.defer = true;
    script.nonce="y9Xv3o27";
    script.id = "facebook-jssdk";
    script.onload = () => {
      if (window.FB) {
        window.FB.init({
          appId: "480483378138136",
          autoLogAppEvents: true,
          cookie: true,
          status: true,
          xfbml: true,
          version: "v20.0",
        });
        window.FB.XFBML.parse();
      }
    };

    if (scriptRef.current === null) {
      document.body.appendChild(script);
      scriptRef.current = script;
    }

    return () => {
      if (scriptRef.current) {
        scriptRef.current.parentNode?.removeChild(scriptRef.current)
        // document.body.removeChild(scriptRef.current);
      }
    };
  }, []);

  return (
    <section className="facebook-page">
      <div
        className="fb-page"
        data-href={pageUrl}
        data-tabs="timeline"
        data-width=""
        data-height=""
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true">
        <blockquote cite={pageUrl} className="fb-xfbml-parse-ignore">
          <a href={pageUrl}>{name}</a>
        </blockquote>
      </div>
    </section>
  );
};

export default Facebook;